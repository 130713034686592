import React from "react";
import {Link, StaticQuery, graphql} from "gatsby";

const CATEGORIES_MENU_QUERY = graphql`
  query GET_CATEGORIES_MENU_ITEMS {
    allWordpressCategory {
      edges {
        node {
          id
          slug
          name
          count
        }
      }
    }
  }
`

const renderCategoriesMenuItem = category => {
  if (category.slug === 'uncategorized') {
    return false;
  } else {
    return (
      <li key={category.id} role="none">
        <Link role="menuitem" to={`/blog/category/${category.slug}`}>{category.name}</Link>
      </li>
    )
  }
}

const CategoriesMenu = props => {
  return (
    <StaticQuery
      query={CATEGORIES_MENU_QUERY}
      render={({
                 allWordpressCategory: categories,
               }) => {
        return (
          <div className="menu-categories">
            <div className="menu-categories__box">
              <p className="menu-categories__filter">Search by category: </p>
              <nav aria-label="Categories menu">
                <ul className="menu-categories__menu">
                  <li role="none"><Link to={`/blog`} role="menuitem">All</Link></li>
                  {categories.edges.map(category =>renderCategoriesMenuItem(category.node))}
                </ul>
              </nav>
            </div>
          </div>
        )
      }}
    />
  )
}

export default CategoriesMenu;
