import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Pagination from '../components/partials/pagination'
import PostList from '../components/partials/single-post'
import SEO from "../components/seo"
import "../styles/pages/blog.scss"
import CategoriesMenu from "../components/partials/categoriesMenu";

class BlogPage extends Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost

    return (
      <Layout>
        <SEO title="Blog" />
        <div className="blog">
          <div className="container">
            <h1 className="title">BLOG</h1>
            <CategoriesMenu/>
            <PostList posts={posts} />
            <Pagination pageContext={pageContext} pathPrefix="/" />
          </div>
        </div>
      </Layout>
    )
  }
}
export default BlogPage


BlogPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query blogQuery($limit: Int!, $skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
