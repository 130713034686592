import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ pageContext, pageContent, pathPrefix }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <nav className="pagination">
      <div className="container">
        <ul className="pagination__list">
          <li className="pagination__item">
            {previousPagePath && (
              <Link className="pagination__link" to={previousPagePath}>Previous page</Link>
            )}
          </li>
          {nextPagePath && (
            <li className="pagination__item"><Link className="pagination__link" to={nextPagePath}>Next page</Link></li>
          )}
        </ul>
      </div>
    </nav>
  )
}


export default Pagination
